

export class SupplierViewModel {


    id: number;
    businessName: string;
    accountId: string;
    abn: string;
    profileImageUrl_Normal: string;
    profileImageUrl_Small: string;
    address: string;
    city: string;
    state: string;
    postalCode: string;
    marketLocation: string;
    pay: string;
    ownerName: string;
    businessEmail: string;
    ownerEmail: string;
    mobile: string;
    phone: string;
    additionalNumber: string;
    active: boolean;
    approved: boolean;
    sellerSubscription:SellerSubscriptionModel;

}
export class SellerSubscriptionModel {

    id: number;
    customerId?: string;
    subscriptionId?: string;
    quantity?: string;
    subscriptionStartDate?: Date;
    subscriptionEndDate?: Date;
    trialStart?: Date;
    trialEnd?: Date;
    cancelAt?: Date;
    amount?: number;
    isPaused?: boolean;
    statusId:number
  }
  export enum SubscriptionStatus {
    Active = 1,
    PendingApproval=2,
    Cancelled = 3,
    Deleted = 4,
    PaymentMethodNotSet=5
  }
  export enum StripeSubscriptionStatus {
    Incomplete=1,
    IncompleteExpired=2,
    Trialing = 3,
    Active=4,
    Cancelled = 5,
    Unpaid =6,
    Deleted=7
  }