import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { environment } from '../../../environments/environment';

import { UtilsService } from 'app/shared/services/utils.service';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';


import { HttpErrorHandler, HandleError } from 'app/base/http-error-handler.service';
import { HttpParams } from '@angular/common/http';
import { GroupedOrdersSummaryResponse } from '../models/buyer/buyer-orders.model';
import { OrderUpdateRequest, PendingOrdersbyBuyer, PendingOrdersbyProduct, ProductLineItemDetails } from '../models/order/PlainProductInOrders.Model';



const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  headers: Headers;
  apiURl: string;

  private handleError: HandleError;



  constructor(private http: HttpClient, private utilsService: UtilsService, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('SellerService');
    this.apiURl = environment.apibaseUrl;
  }
  GetPendingOrdersGroupbyBuyer(): Observable<PendingOrdersbyBuyer[]> {
    const url = `${this.apiURl}/api/v1/Ordering/GetPendingOrdersGroupbyBuyer`;

    return this.http.get<PendingOrdersbyBuyer[]>(url, httpOptions).pipe(
      catchError(this.handleError<PendingOrdersbyBuyer[]>('GetPendingOrdersGroupbyBuyer'))
    );
  }

  GetPendingOrdersGroupbyProduct(): Observable<PendingOrdersbyProduct[]> {
    const url = `${this.apiURl}/api/v1/Ordering/GetPendingOrdersGroupbyProduct`;

    return this.http.get<PendingOrdersbyProduct[]>(url, httpOptions).pipe(
      catchError(this.handleError<PendingOrdersbyProduct[]>('GetPendingOrdersGroupbyProduct'))
    );
  }

  ConfirmItem(OrderUpdateRequest: OrderUpdateRequest): Observable<any> {
    const url = `${this.apiURl}/api/v1/Ordering/ConfirmItem`;

    return this.http.put(url, OrderUpdateRequest, httpOptions).pipe(
      catchError(this.handleError<any>('ConfirmItem'))
    );
  }

  RejectItem(OrderUpdateRequest: OrderUpdateRequest): Observable<any> {
    const url = `${this.apiURl}/api/v1/Ordering/RejectItem`;

    return this.http.put(url, OrderUpdateRequest, httpOptions).pipe(
      catchError(this.handleError<any>('RejectItem'))
    );
  }
  ConfirmAllProductForBuyer(OrderUpdateRequest: OrderUpdateRequest): Observable<boolean> {
    const url = `${this.apiURl}/api/v1/Ordering/ConfirmAllProductForBuyer`;

    return this.http.put(url, OrderUpdateRequest, httpOptions).pipe(
      catchError(this.handleError<any>('ConfirmAll'))
    );
  }
  ConfirmProductInAllOrders(OrderUpdateRequest: OrderUpdateRequest): Observable<boolean> {
    const url = `${this.apiURl}/api/v1/Ordering/ConfirmProductInAllOrders`;

    return this.http.put(url, OrderUpdateRequest, httpOptions).pipe(
      catchError(this.handleError<any>('ConfirmAll'))
    );
  }
  ChangeRequestedQuantity(OrderUpdateRequest: OrderUpdateRequest): Observable<any> {
    const url = `${this.apiURl}/api/v1/Ordering/ChangeRequestedQuantity`;

    return this.http.put(url, OrderUpdateRequest, httpOptions).pipe(
      catchError(this.handleError<any>('ChangeRequestedQuantity'))
    );
  }

  GetOrdersforSupplier(): Observable<GroupedOrdersSummaryResponse[]> {
    const url = `${this.apiURl}/api/v1/Ordering/GetOrdersforSupplier`;

    return this.http.get<GroupedOrdersSummaryResponse[]>(url, httpOptions).pipe(
      catchError(this.handleError<GroupedOrdersSummaryResponse[]>('GetOrdersforSupplier'))
    );
  }
  GetOrderLineItemsDetails(OrderId: number): Observable<ProductLineItemDetails[]> {
    const url = `${this.apiURl}/api/v1/Ordering/GetOrderLineItemsDetails?OrderId=${OrderId}`;

    return this.http.get<ProductLineItemDetails[]>(url, httpOptions).pipe(
      catchError(this.handleError<ProductLineItemDetails[]>('GetOrderLineItemsDetails'))
    );
  }


}
