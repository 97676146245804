import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { environment } from 'environments/environment';
import { Message } from 'ng-chat';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DashBoardViewModel } from '../models/dashboard/dashboardViewModel';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  headers: Headers;
  apiURl: string;

  private handleError: HandleError;



  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('ChatService');
    this.apiURl = environment.apibaseUrl;
  }

  GetChatHistoryForBuyer(buyerId: number): Observable<Message[]> {
    const url = `${this.apiURl}/api/v1/NgChat/GetChatHistory?buyerId=${buyerId}`;

    return this.http.get<any>(url).pipe(
      catchError(this.handleError<any>('GetChatHistoryForBuyer'))
    );

  }
  GetChatHistory(ChatId: number): any {
    const url = `${this.apiURl}/api/v1/NgChat?ChatId=${ChatId}`;

    return this.http.get<any>(url).pipe(
      catchError(this.handleError<any>('GetChatHistory'))
    );
  }

}


