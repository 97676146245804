import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { MarketUpdateDialog } from 'app/dialogs/marketUpdate/marketUpdate.dialog';
import { SupplierGroupsViewModel } from 'app/shared/models/promotions/marketing.model';
import { MarketingService } from 'app/shared/services/marketing.service';
import { UtilsService } from 'app/shared/services/utils.service';

@Component({
  selector: 'app-manage-market',
  templateUrl: './market-updates.component.html',
  styleUrls: ['./market-updates.component.css']
})
export class MarketUpdatesComponent implements OnInit {
  supplierGroupsViewModel: SupplierGroupsViewModel;
  SupplierId: number;
  public model = {
    editorData: '<p>Hello, world!</p>'
  };
  constructor(private AuthService: MsalService, private utilities: UtilsService, private marketingService: MarketingService, public dialog: MatDialog, private snackbar: MatSnackBar, private router: Router) {
  }
  ngOnInit(): void {
    
  }

  getclass(isactive: boolean) {
    if (isactive) {
      return '';
    }

    else { return 'addOverlay'; }
  }
  @HostListener("window:keydown", ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (e.code == "ArrowRight") {
      this.step++;

    }
    else if (e.code == "ArrowLeft") {
      this.step--;
    }
    // //console.log(`${e.code}`);
  }
  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }


  openmarketupdatesdialog(Id: number): void {

    //debugger;
    const dialogRef = this.dialog.open(MarketUpdateDialog, {
      data: {},
      height: '800px',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {

    });

  }


}
