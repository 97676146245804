<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-header card-header-tabs card-header-success">
            Sellers

          </div>
          <div class="card-body">
            <form novalidate #myForm="ngForm">
            <kendo-grid 
            #grid
            [kendoGridBinding]="gridView"
                    kendoGridSelectBy="id"
                    [selectedKeys]="mySelection"
                    [loading]="loading" 
                    [pageSize]="10"
                    [pageable]="true"
                    [sortable]="true"
                    [groupable]="false"
                    [reorderable]="true"
                    [resizable]="true"
                    [height]="300"
                    [columnMenu]="{ filter: true }"
                    [selectable]="selectableSettings"
                    kendoGridSelectBy="id"
                    [kendoGridSelectBy]="mySelectionKey"
                    (selectedKeysChange)="keyChange($event)"
                    [selectable]="{enabled: true, checkboxOnly: true}"
               >      
                   
               <kendo-grid-column field="abn" title="ABN"  [width]="220">
              </kendo-grid-column> 
               <kendo-grid-column field="businessName" title="Business Name"  [width]="220">
              </kendo-grid-column> 
              <kendo-grid-column field="businessEmail" title="Business Email"  [width]="220">
              </kendo-grid-column> 
              <kendo-grid-column field="ownerName" title="Owner Name"  [width]="220">
              </kendo-grid-column> 
              <kendo-grid-column field="ownerEmail" title="Owner Email"  [width]="220">
              </kendo-grid-column> 
              <kendo-grid-column field="mobile" title="Mobile"  [width]="220">
              </kendo-grid-column> 
              <kendo-grid-column field="phone" title="Phone"  [width]="220">
              </kendo-grid-column> 
              <kendo-grid-command-column title="Trial End Date" [width]="250">
                <ng-template let-dataItem kendoGridCellTemplate  let-rowIndex="rowIndex" >
                  <div class="font-weight-bold text-left">
                    <input type="datetime-local" name="trialEnd-{{rowIndex}}" [disabled]="isTrialDisabled(dataItem.sellerSubscription?.statusId)"  [(ngModel)]="dataItem.sellerSubscription && dataItem.sellerSubscription.trialEnd" #trialEnd="ngModel" [dateValidator] class="form-control"/>
                  </div> 
                  <p *ngIf="trialEnd.errors?.dateValidator " class="text-danger">Trial Date must be greater than today date </p>
           
                </ng-template>
              </kendo-grid-command-column>
              <kendo-grid-column  title="Pause Payment Collection" [width]="200">              
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span class="left-align">
                    <kendo-switch name="isPaused-{{rowIndex}}" [disabled]="dataItem.sellerSubscription==null" [(ngModel)]="dataItem.sellerSubscription && dataItem.sellerSubscription.isPaused"  #paused="ngModel" class="pl-5" onLabel="Yes" offLabel="No">
                    </kendo-switch>          
                  </span>       
                </ng-template>
            </kendo-grid-column>   
              <kendo-grid-command-column title="" [width]="220">
                <ng-template let-dataItem kendoGridCellTemplate >
                  <div *ngIf="!dataItem.approved" class="font-weight-bold text-center">
                    <button  class="btn btn-success"  (click)="approve(dataItem)">Approve</button>
                  </div>
                  <div *ngIf="dataItem.approved" class="font-weight-bold text-center">
                    Approved
                  </div>
                  
                  
                </ng-template>
              </kendo-grid-command-column>
              
         </kendo-grid> 
        </form>
          </div>
          <mat-card-actions align="end">
            <button mat-button (click)="saveChanges()" [disabled]="!myForm.form.valid "  cdkFocusInitial>Save</button>
          </mat-card-actions>
        </div>
      </div>
    </div>
   
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading Suppliers... </p>
</ngx-spinner>
