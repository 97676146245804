import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { environment } from '../../../environments/environment';

import { UtilsService } from 'app/shared/services/utils.service';

import { SellerViewModel, SupplierViewModel } from 'app/shared/models/seller/index';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';


import { HttpErrorHandler, HandleError } from 'app/base/http-error-handler.service';
import { HttpParams } from '@angular/common/http';
import { BuyerViewModel } from '../models/promotions/marketing.model';
import { NotificationViewModel } from '../models/seller/notificationViewmodel';



const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  //  options: RequestOptions;
  headers: Headers;
  apiURl: string;

  private handleError: HandleError;



  constructor(private http: HttpClient, private utilsService: UtilsService, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('SellerService');
    this.apiURl = environment.apibaseUrl;
  }
  getAdmin(): Observable<SellerViewModel> {
    const url = `${this.apiURl}/api/v1/seller`;
    // const options = '' ?
    //  { params: new HttpParams().set('name', '') } : {};
    //  var options2 = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    // };
    return this.http.get<SellerViewModel>(url, httpOptions).pipe(
      catchError(this.handleError<SellerViewModel>('getSeller'))
    );
  }
  GetBuyersNotifications(): Observable<NotificationViewModel[]> {
    const url = `${this.apiURl}/api/v1/Supplier/GetBuyersNotifications`;
    // const options = '' ?
    //  { params: new HttpParams().set('name', '') } : {};
    //  var options2 = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    // };
    return this.http.get<NotificationViewModel[]>(url, httpOptions).pipe(
      catchError(this.handleError<NotificationViewModel[]>('GetBuyersNotifications'))
    );
  }

  CloseNotification(NotificationViewModel: NotificationViewModel): Observable<boolean> {
    const url = `${this.apiURl}/api/v1/Supplier/CloseNotification`;
    // const options = '' ?
    //  { params: new HttpParams().set('name', '') } : {};
    //  var options2 = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    // };
    return this.http.put<boolean>(url, NotificationViewModel, httpOptions).pipe(
      catchError(this.handleError<boolean>('CloseNotification'))
    );
  }
  getBuyer(BuyerId: number): Observable<BuyerViewModel> {
    const url = `${this.apiURl}/api/v1/Buyer?Id=${BuyerId}`;
    // const options = '' ?
    //  { params: new HttpParams().set('name', '') } : {};
    //  var options2 = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    // };
    return this.http.get<BuyerViewModel>(url, httpOptions).pipe(
      catchError(this.handleError<BuyerViewModel>('getBuyer'))
    );
  }
  getSupplier(): Observable<SupplierViewModel> {
    const url = `${this.apiURl}/api/v1/Supplier`;
    // const options = '' ?
    //  { params: new HttpParams().set('name', '') } : {};
    //  var options2 = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    // };
    return this.http.get<SupplierViewModel>(url, httpOptions).pipe(
      catchError(this.handleError<SupplierViewModel>('getSupplier'))
    );
  }
  updateSupplier(Supplier: SupplierViewModel): Observable<SupplierViewModel> {
    const url = `${this.apiURl}/api/v1/Supplier/Update`;
    // const options = '' ?
    //  { params: new HttpParams().set('name', '') } : {};
    //  var options2 = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    // };
    return this.http.post<SupplierViewModel>(url, Supplier, httpOptions).pipe(
      catchError(this.handleError<SupplierViewModel>('updateSupplier'))
    );
  }
}