<form [formGroup]="subscriptionForm" (ngSubmit)="onSubmit()">
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">Approve New Seller</h4>
            </div>
            <div class="card-body">
              <div class="row">

                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <mat-form-field>
                        <mat-label>Pricing Tier</mat-label>
                        <mat-select matNativeControl formControlName="priceId" name="Price" required>
                          <mat-option value="-1" selected>Please select pricing tier</mat-option>
                          <mat-option *ngFor="let c of prices" [value]="c.id">
                            {{c.nickname}} ({{c.amount | currency}})
                          </mat-option>
                        </mat-select>
                        <mat-error>Please select Pricing Tier</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="Trial Days*" formControlName="trialDays">
                        <mat-error>Invalid Number of Trial Days</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="Discount*" formControlName="discount">
                        <mat-error>Invalid Discount value</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="Discount Period in months*" formControlName="discountPeriod">
                        <mat-error>Invalid Discount period</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4 mr-3 text-center">
                    <button mat-button type="submit" [disabled]="!subscriptionForm.valid" class="btn btn-primary">
                      Approve
                    </button>
                  </div>
                  <div class="col-md-4 text-center">
                    <button mat-button class="btn btn-danger" [mat-dialog-close]="true">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>