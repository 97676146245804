import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { SellerViewModel } from 'app/shared/models/seller/sellerViewModel';
import { AuthorizeService } from 'app/shared/services/authorize.service';
import { ChatService } from 'app/shared/services/chat.service';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { MarketingService } from 'app/shared/services/marketing.service';
import { AdminService } from 'app/shared/services/Admin.Service';
import { ChatAdapter, IChatController, IChatParticipant } from 'ng-chat';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/filter';
import { filter, takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})

export class AdminLayoutComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();

  currentTheme = 'dark-theme';
  public adapter: ChatAdapter;
  public userId: string = '99999';
  public showchat: boolean = false;
  @ViewChild('ngChatInstance')
  protected ngChatInstance: IChatController;
  constructor(private eventEmitterService: EventEmitterService,
    private msalBroadcastService: MsalBroadcastService,
    private marketingService: MarketingService, private chatService: ChatService,
    private adminservice: AdminService, private AuthService: AuthorizeService,
    private spinner: NgxSpinnerService) {
  }
  ngOnInit() {

    this.eventEmitterService.invokeShowSpinnerfunction
      .subscribe(() => {
        this.ShowSpinner();
      });
    this.eventEmitterService.invokeHideSpinnerfunction
      .subscribe(() => {
        this.HideSpinner();
      });


    if (this.AuthService.isloggedIn()) {
      // this.adminservice.getAdmin().subscribe((result) => {        
      //   this.userId = result.supplierId.toString();
      //   this.showchat = true;

      // })
    }
    else {

      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$)
        )
        .subscribe((result) => {
          // this.adminservice.getAdmin().subscribe((result) => {
          //   this.userId = result.supplierId.toString();
          //   this.showchat = true;
          // })
        });

    }
  }
  ngAfterViewInit() {



    if (this.eventEmitterService.subsVar == undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
        invokeOpenChatFunction.subscribe((user: IChatParticipant) => {
          this.openchat(user);
        });



    }
  }
  HideSpinner() {
    this.spinner.hide();

  }
  ShowSpinner() {
    this.spinner.show();

  }
  openchat(user: IChatParticipant) {
    console.info("open chat in Layout:", [user])

    this.ngChatInstance.triggerOpenChatWindow(user);
  }
  public messageSeen(event: any) {
    console.info("messageSeenEvent", event);
  }
}
