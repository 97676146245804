import { Routes } from '@angular/router';

import { DashboardComponent } from '../../dashboard/dashboard.component';
import { OnboardingComponent } from 'app/onboarding/onboarding.component';
import { MarketUpdatesComponent } from 'app/market-updates/market-updates.component';
import { SellersComponent } from 'app/subscribers/management/sellers.component';
export const AdminLayoutRoutes: Routes = [
    {
        path: 'dashboard',
        component: DashboardComponent
    },
    { path: 'onboarding', component: OnboardingComponent },
    { path: 'market-updates', component: MarketUpdatesComponent },
    { path: 'sellers', component: SellersComponent },
];
