import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { environment } from '../../../environments/environment';

import { UtilsService } from 'app/shared/services/utils.service';

import { ProductCategory, ProductGrade, ProductGrower, ProductVarital, ProductViewMdel } from 'app/shared/models/catalogue/index';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';


import { HttpErrorHandler, HandleError } from 'app/base/http-error-handler.service';
import { HttpParams } from '@angular/common/http';
import { PromotionViewMdel } from '../models/catalogue/promotionViewModel';



const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  //  options: RequestOptions;
  headers: Headers;
  apiURl: string;
  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('ProductService');
    this.apiURl = environment.apibaseUrl;
  }



  getProductsforSupplier(): Observable<ProductViewMdel[]> {

    const url = `${this.apiURl}/api/v1/Catalogue/GetProductsListedforSupplier`;
    return this.http.get<ProductViewMdel[]>(url, httpOptions).pipe(
      tap(results => {
        results.sort(function (a, b) { return a.id - b.id })
      }),
      catchError(this.handleError<ProductViewMdel[]>('getProductsforSupplier'))

    );
  }

  getProductCategories(): Observable<ProductCategory[]> {

    const url = `${this.apiURl}/api/v1/Catalogue/GetProductCategories`;
    return this.http.get<ProductCategory[]>(url, httpOptions).pipe(
      catchError(this.handleError<ProductCategory[]>('GetProductCategories'))
    );
  }
  getProductGrades(): Observable<ProductGrade[]> {

    const url = `${this.apiURl}/api/v1/Catalogue/GetProductGrades`;
    return this.http.get<ProductGrade[]>(url, httpOptions).pipe(
      catchError(this.handleError<ProductGrade[]>('getProduGrades'))
    );
  }
  getProductGrowers(): Observable<ProductGrower[]> {
    const url = `${this.apiURl}/api/v1/Catalogue/GetProductGrowers`;
    return this.http.get<ProductGrower[]>(url, httpOptions).pipe(
      catchError(this.handleError<ProductGrower[]>('getProductGrowers'))
    );
  }
  GetProductVarietals(categoryId): Observable<ProductVarital[]> {
    const url = `${this.apiURl}/api/v1/Catalogue/GetProductVarietals?ProductCategoryId=${categoryId}`;
    return this.http.get<ProductVarital[]>(url, httpOptions).pipe(
      catchError(this.handleError<ProductVarital[]>('GetProductVarietals'))
    );
  }
  updateProductStock(ProductId: number, stocktoAdd: number): Observable<ProductViewMdel> {

    const url = `${this.apiURl}/api/v1/Catalogue/UpdateProductStock?Id=${ProductId}&NewStock=${stocktoAdd}`;
    return this.http.put<ProductViewMdel>(url, httpOptions).pipe(
      catchError(this.handleError<ProductViewMdel>('updateProductStock'))
    );
  }

  CreateProuctForSupplier(Product: ProductViewMdel): Observable<ProductViewMdel> {

    const url = `${this.apiURl}/api/v1/Catalogue/CreateProuctForSupplier`;
    return this.http.post<ProductViewMdel>(url, Product, httpOptions).pipe(
      catchError(this.handleError<ProductViewMdel>('CreateProuctForSupplier'))
    );
  }
  UpdateProduct(Product: ProductViewMdel): Observable<ProductViewMdel> {

    const url = `${this.apiURl}/api/v1/Catalogue/UpdateProduct`;
    return this.http.put<ProductViewMdel>(url, Product, httpOptions).pipe(
      catchError(this.handleError<ProductViewMdel>('UpdateProduct'))
    );
  }
  UpdateProductUnitPrice(Product: ProductViewMdel): Observable<ProductViewMdel> {

    const url = `${this.apiURl}/api/v1/Catalogue/UpdateProductUnitPrice`;
    return this.http.put<ProductViewMdel>(url, Product, httpOptions).pipe(
      catchError(this.handleError<ProductViewMdel>('UpdateProductUnitPrice'))
    );
  }
  activateDeactivateProduct(ProductId: number): Observable<ProductViewMdel> {

    const url = `${this.apiURl}/api/v1/Catalogue/ActivateDeActivateProduct?Id=${ProductId}`;
    // const options = '' ?
    //  { params: new HttpParams().set('name', '') } : {};
    //  var options2 = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    // };
    return this.http.put<ProductViewMdel>(url, httpOptions).pipe(
      catchError(this.handleError<ProductViewMdel>('activateDeactivateProduct'))
    );
  }
  ActivatePromotion(Promotion: PromotionViewMdel): Observable<boolean> {

    const url = `${this.apiURl}/api/v1/Catalogue/ActivatePromotion`;
    // const options = '' ?
    //  { params: new HttpParams().set('name', '') } : {};
    //  var options2 = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    // };
    return this.http.put<boolean>(url, Promotion, httpOptions).pipe(
      catchError(this.handleError<boolean>('ActivatePromotion'))
    );
  }
  DEActivatePromotion(ProductId: number): Observable<boolean> {

    const url = `${this.apiURl}/api/v1/Catalogue/DeActivatePromotion?Id=${ProductId}`;
    // const options = '' ?
    //  { params: new HttpParams().set('name', '') } : {};
    //  var options2 = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    // };
    return this.http.put<boolean>(url, httpOptions).pipe(
      catchError(this.handleError<boolean>('DEActivatePromotion'))
    );
  }

}

