import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { chartdata, DashBoardViewModel, Months } from 'app/shared/models/dashboard/dashboardViewModel';
import { SellerViewModel } from 'app/shared/models/seller';
import { DashBoardService } from 'app/shared/services/dashboard.service';
import { AdminService } from 'app/shared/services/Admin.Service';
import { UtilsService } from 'app/shared/services/utils.service';
import * as Chartist from 'chartist';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { EventEmitterService } from 'app/shared/services/event-emitter.service';


interface Account extends AccountInfo {
  idTokenClaims?: {
    roles?: string[];
    sub?: string;
    aud?: string;
    nonce?: string;
    iat?: number;
    auth_time?: number;
    given_name?: string;
    family_name?: string;
    idp?: string;
    oid?: string;
    city?: string;
    country?: string;

  }

}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  SupplierId: number;
  dashBoardViewModel: DashBoardViewModel;
  dataCompletedTasksChart: chartdata;
  dataDailySalesChart: chartdata;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private authService: MsalService,
    private dashBoardService: DashBoardService, private utilities: UtilsService,
    private spinner: NgxSpinnerService,

  ) {
  }
  ngOnInit() {    
    this.initDashBoard();
  }


  async initDashBoard() {   
  }

  getPercentageChange(oldNumber, newNumber) {

    
  }
  getdailyPercentageChange(oldNumber, newNumber) {
    
  }

  LoadChart(obj: chartdata) {    

  }

  loaddailychart(obj: chartdata) {

  }
  startAnimationForLineChart(chart) {   
  };
  startAnimationForBarChart(chart) {
    
  };



}
