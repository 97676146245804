<div class="logo">
  <a href="https://app.seller.demo.omniorder.com.au" class="simple-text">
    <div class="logo-img">
      <img src="/assets/img/structure/logo.png">
    </div>
  </a>
</div>
<div class="sidebar-wrapper" *ngIf="loggedIn">
  <div *ngIf="isMobileMenu()">
    <!-- <form class="navbar-form">
      <span class="bmd-form-group">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </span>
    </form> -->
    <ul class="nav navbar-nav nav-mobile-menu">
      <!-- <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)">
          <i class="material-icons">dashboard</i>
          <p>
            <span class="d-lg-none d-md-block">Stats</span>
          </p>
        </a>
      </li> -->
      <!-- <li class="nav-item dropdown">
        <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="material-icons">notifications</i>
          <span class="notification">5</span>
          <p>
            <span class="d-lg-none d-md-block">notifications</span>
          </p>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" href="#">Mike John responded to your email</a>
          <a class="dropdown-item" href="#">You have 5 new tasks</a>
          <a class="dropdown-item" href="#">You're now friend with Andrew</a>
          <a class="dropdown-item" href="#">Another Notification</a>
          <a class="dropdown-item" href="#">Another One</a>
        </div>
      </li> -->
      <li class="nav-item dropdown" *ngIf="loggedIn">
        <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="material-icons" style="color:rgb(15, 78, 29)">person</i>
          <p>
            <span class="d-lg-none d-md-block"> {{username}}</span>
          </p>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item nav-link" mat-button color="info" href="javascript:void(0)" (click)="editProfile()">

            <i class="material-icons" style="color:rgb(15, 78, 29)"> mode_edit</i>
            Edit Profile
          </a>
          <a class="dropdown-item nav-link" mat-button color="error" href="javascript:void(0)" (click)="logout()">

            <i class="material-icons green" style="color:rgb(54, 1, 1)"> logout</i>

            Logout

          </a>

        </div>
      </li>
    </ul>
  </div>
  <ul class="nav" *ngIf="loggedIn">
    <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
      <a class="nav-link" [routerLink]="[menuItem.path]">
        <i class="material-icons">{{menuItem.icon}}</i>
        <p>{{menuItem.title}}</p>
      </a>
    </li>
  </ul>
</div>
