import { Inject, Injectable, EventEmitter, Output, Type } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { b2cPolicies } from 'app/config/app-config';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AdminService } from './Admin.Service';
import { Account } from "app/shared/models/account"
import { UtilsService } from './utils.service';
import { promise } from 'protractor';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthorizeService {

    calbackcompleted = false;
    loggedIn = false;
    UserName = '';

    private readonly _destroying$ = new Subject<void>();
    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private utilities: UtilsService) { }

    updateLoggedInStatus() {

        // this.msalBroadcastService.msalSubject$
        //     .pipe(
        //         filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        //         takeUntil(this._destroying$)

        //     )
        //     .subscribe((result: EventMessage) => {
        //         //console.log(result);
        //         const payload = result.payload as AuthenticationResult;
        //         this.authService.instance.setActiveAccount(payload.account);
        //         this.checkAndSetActiveAccount();
        //         this.utilities.AddObjectToSession("AccessToken", payload['accessToken']);
        //     });


        // this.msalBroadcastService.inProgress$
        //     .pipe(
        //         filter((status: InteractionStatus) => status === InteractionStatus.None),
        //         takeUntil(this._destroying$)
        //     )
        //     .subscribe(() => {
        //         alert(status);
        //         // this.setLoggedIn();
        //         // this.checkAndSetActiveAccount();
        //     });

        // this.msalBroadcastService.msalSubject$.pipe(
        //     filter(
        //         (msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS), takeUntil(this._destroying$)).subscribe((result: EventMessage) => {
        //             this.utilities.AddObjectToSession('AccessToken', result.payload['accessToken']);
        //         });

        this.authService.instance.addEventCallback((event) => {
            // set active account after redirect
            // alert(event.eventType);

            switch (event.eventType) {
                case EventType.LOGIN_SUCCESS:
                case EventType.ACQUIRE_TOKEN_SUCCESS:
                    if (event.payload.account) {
                        this.setLoggedIn();
                        this.checkAndSetActiveAccount();
                        this.utilities.AddObjectToSession("AccessToken", event.payload['accessToken']);
                    }
                    break;
                case EventType.LOGOUT_END:
                    this.utilities.RemoveObjectfromSession("AccessToken");
                    break;

                default:
                    break;
            }
        });


    }

    login() {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            this.loginWithPopup();
        } else {
            this.loginWithRedirect();
        }
    }
    editProfile() {
        let editProfileFlowRequest = {
            scopes: ["openid"],
            authority: b2cPolicies.authorities.editProfile.authority,
        };
        this.authService.loginRedirect(editProfileFlowRequest);

    }

    public getToken() {

        return this.utilities.getObjectFromSession('AccessToken');
    }

    getActiveAccount(): AccountInfo | null {
        return this.authService.instance.getActiveAccount();
    }

    private checkAndSetActiveAccount() {
        /**
        * If no active account set but there are accounts signed in, sets first account to active account
        * To use active account set here, subscribe to inProgress$ first in your component
        * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
        */
        let activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            let currentActiveAccount: Account = accounts[0];
            this.authService.instance.setActiveAccount(currentActiveAccount);
        }


    }
    private waites() {
        setTimeout(() => {
            if (!this.calbackcompleted) {
                this.waites();
            }
            //<<<---using ()=> syntax
        }, 3000);
    }
    public isloggedIn(): boolean {

        return this.authService.instance.getAllAccounts().length > 0
    }

    private setLoggedIn() {
        this.loggedIn = this.authService.instance.getAllAccounts().length > 0;

        let account = this.authService.instance.getAllAccounts()[0];

        this.UserName = account.idTokenClaims["given_name"];

    }

    private loginWithPopup() {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);

                });
        } else {
            this.authService.loginPopup()
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
        }
    }

    private loginWithRedirect() {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }

    logout() {
        this.clearCache();
        this.authService.logout({
            postLogoutRedirectUri: environment.postLogoutRedirectUri
          });
    }

    clearCache(): void {        
        localStorage.clear();
        sessionStorage.clear();
        // Clear cookies if necessary
        document.cookie.split(";").forEach((c) => {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/;domain=.omniorder.com.au");
        });
    }

    destroy() {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}