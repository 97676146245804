import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
// import { PromotionGroupCollection } from '../mocks/promotionGroups-mock';
// import {  } from '../models/promotions/marketing.model';

@Injectable({
  providedIn: 'root'
})
export class BuyerService {

  constructor() { }
  getBuyerOrders(): Observable<any[]> {
    return null
  }

  // getPromotionGroups(): Observable<PromotionGroup[]> {
  //   return of(PromotionGroupCollection)
  // }
}
