import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Services
import { OrderService } from './services/order.service';
import { BuyerService } from './services/buyer.service';
import { ProductService } from './services/product.service';
import { EventEmitterService } from './services/event-emitter.service';
import { AdminService } from './services/Admin.Service';
import { MarketingService } from './services/marketing.service';
import { DashBoardService } from './services/dashboard.service';
import { UtilsService } from './services/utils.service';
// Pipes:
import { UppercasePipe } from './pipes/uppercase.pipe';
import { AuthorizeService } from './services/authorize.service';
import { DialogService } from '@progress/kendo-angular-dialog';




@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // No need to export as these modules don't expose any components/directive etc'
  ],
  declarations: [
    UppercasePipe
  ],
  exports: [
    UppercasePipe,
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: EventEmitterService },
        { provide: UtilsService },
        { provide: AuthorizeService },
        { provide: OrderService },
        { provide: BuyerService },
        { provide: MarketingService },
        { provide: ProductService },
        { provide: AdminService },
        { provide: DashBoardService },

      ]
    };
  }
}