<div class="main-content">
  <div class="container-fluid">
  <div class="row mat-headline text-center" >
        OmniOrder Admin Portal
  </div>
    <!--<div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-warning card-header-icon">
            <div class="card-icon">
              <i class="material-icons">push_pin</i>
            </div>
            <p class="card-category"><a routerLink="/ordersApproval">Pending Order</a></p>
            <h3 class="card-title">{{dashBoardViewModel?.statistcs?.pendingOrders}}
              <small>Order</small>
            </h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons text-danger">warning</i>
              <a href="javascript:void(0)">Track Order...</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-primary card-header-icon">
            <div class="card-icon">
              <i class="material-icons">attach_money</i>
            </div>
            <p class="card-category"><a routerLink="/tracking">Sales</a></p>
            <h3 class="card-title">${{dashBoardViewModel?.statistcs?.last7dayRevenue}}</h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">date_range</i> Last 7 days
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-danger card-header-icon">
            <div class="card-icon">
              <i class="material-icons">eco</i>
            </div>
            <p class="card-category"><a routerLink="/inventory">Available Products</a></p>
            <h3 class="card-title">{{dashBoardViewModel?.statistcs?.availableProducts}}</h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              {{dashBoardViewModel?.statistcs?.productsOnPromotion}} &nbsp;<a routerLink="/inventory">On Promotion</a>
            </div>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-info card-header-icon">
            <div class="card-icon"> -->
              <!-- <i class="fa fa-twitter"></i> -->
              <!--<i class="material-icons">group</i>
            </div>
            <p class="card-category"> <a routerLink="/customers">Customers</a></p>
            <h3 class="card-title">{{dashBoardViewModel?.statistcs?.numberOfCustomers}}</h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              {{dashBoardViewModel?.statistcs?.numberOfGroups}} &nbsp;<a routerLink="/market">Groups</a>
            </div>
          </div>
        </div>
      </div>
    </div>-->
    <div class="row">
      <div class="col-md-6">
        <div class="card card-chart">
          <div class="card-header card-header-success">
            <div class="ct-chart" id="dailySalesChart"></div>
          </div>
          <div class="card-body">
            <h4 class="card-title">Daily Sales</h4>

            <p class="card-category" *ngIf="dataDailySalesChart?.percentage!=0">
              <span class="text-success" *ngIf="dataDailySalesChart?.increased"><i class="fa fa-long-arrow-up"></i>
                {{dataDailySalesChart?.percentage}}% increase</span>
              <span class="text-warning" *ngIf="!dataDailySalesChart?.increased"><i class="fa fa-long-arrow-down"></i>
                {{dataDailySalesChart?.percentage}}% decrease</span>
              in daily sales.
            </p>
            <p class="card-category" *ngIf="dataDailySalesChart?.percentage==0">
              <span class="text-success"><i class="fa fa-pause"></i>
                no change</span>

              in daily sales.
            </p>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">access_time</i> live data
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-4">
              <div class="card card-chart">
                  <div class="card-header card-header-warning">
                      <div class="ct-chart" id="websiteViewsChart"></div>
                  </div>
                  <div class="card-body">
                      <h4 class="card-title">Email Subscriptions</h4>
                      <p class="card-category">Last Campaign Performance</p>
                  </div>
                  <div class="card-footer">
                      <div class="stats">
                          <i class="material-icons">access_time</i> campaign sent 2 days ago
                      </div>
                  </div>
              </div>
          </div> -->
      <div class="col-md-6">
        <div class="card card-chart">
          <div class="card-header card-header-primary">
            <div class="ct-chart" id="completedTasksChart"></div>
          </div>
          <div class="card-body">
            <h4 class="card-title">Order confirmation rate</h4>
            <p class="card-category" *ngIf="dataCompletedTasksChart?.percentage!=0">
              <span class="text-success" *ngIf="dataCompletedTasksChart?.increased"><i class="fa fa-long-arrow-up"></i>
                {{dataCompletedTasksChart?.percentage}}% increase</span>
              <span class="text-warning" *ngIf="!dataCompletedTasksChart?.increased"><i
                  class="fa fa-long-arrow-down"></i> {{dataCompletedTasksChart?.percentage}}% decrease</span>
              in current month sales.</p>
            <p class="card-category" *ngIf="dataCompletedTasksChart?.percentage==0">
              <span class="text-success"><i class="fa fa-pause"></i>
                no change</span>
              in current month sales.
            </p>

          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">access_time</i> updated 2 days ago.
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
