import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UploadService } from 'app/shared/services/upload.service';
import { SupplierOnboardingViewModel } from 'app/shared/models/seller/supplierOnboardingViewModel';
import { SupplierService } from 'app/shared/services/supplier.service';

@Component({
  selector: 'dialogs-onboarding',
  templateUrl: './onboarding.dialog.html',
  styleUrls: ['./onboarding.dialog.css']
})
export class OnboardingDialog implements OnInit {
  calbackcompleted: boolean;
  
  constructor(
    public dialogRef: MatDialogRef<OnboardingDialog>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: { editmode: boolean, OnboardingModel: SupplierOnboardingViewModel },
    private uploadService: UploadService, private supplierService: SupplierService
  ) { }


  ngOnInit(): void {
    //console.log(this.dialogdata.Product);
    //this.loadLockups();
  }
  
  saveOrUpdateSupplierOnboarding() {
    //console.log('updated');
    this.calbackcompleted = false;
    if (this.dialogdata.editmode) {
      // this.ProductService.UpdateProduct(this.dialogdata.Product).subscribe((result) => {

      //   this.ProductService.getProductsforSupplier().subscribe(
      //     (data) => {
      //       this.Products = data;
      //       // //console.log(this.Products);
      //       this.calbackcompleted = true;

      //     }
      //   );
      // });
    }
    else {
      this.supplierService.onboardNewSupplier(this.dialogdata.OnboardingModel).subscribe((result) => {
        // this.ProductService.getProductsforSupplier().subscribe(
        //   (data) => {
        //     this.Products = data;
        //     // //console.log(this.Products);
        //     this.calbackcompleted = true;

        //   }
        // );
        alert("Onboarding completed successfully");
        this.calbackcompleted = true;
      });
    }
    this.waites();

    this.dialogRef.close(true);
  }
  waites() {
    setTimeout(() => {
      if (!this.calbackcompleted) {
        this.waites();
      }
      //<<<---using ()=> syntax
    }, 3000);
  }

  getErrorMessage() {    
  }
  formatLabel(value: number) {
    if (value <= 100) {
      return value + '%';
    }

    return value;
  }
  updateSetting(event) {

    //this.dialogdata.Product.stockThreshold = Math.round((this.dialogdata.Product.threshold * this.dialogdata.Product.stock) / 100)
  }

  uploadCustomers(files) {
    const formData = new FormData();    
    if (files[0]) {
      // Todod Append Product Id
      formData.append('550c0516-3dac-40e7-95e6-ba2e9d3a40a7/' + files[0].name, files[0]);
    }
    this.uploadService
      .uploadFile(formData)      
      .subscribe(
        (data) => {
          this.dialogdata.OnboardingModel.customersCSV = data.absolutePath; 
          alert('upload completed successfully');
        }
      );
  }
  uploadProducts(files) {
    const formData = new FormData();    
    if (files[0]) {
      // Todod Append Product Id
      formData.append('550c0516-3dac-40e7-95e6-ba2e9d3a40a7/' + files[0].name, files[0]);
    }
    this.uploadService
      .uploadFile(formData)      
      .subscribe(
        (data) => {
          this.dialogdata.OnboardingModel.productsCSV = data.absolutePath;   
          alert('upload completed successfully');       
        }
      );
  }
  uploadSupplierInfo(files) {
    const formData = new FormData();    
    if (files[0]) {
      // Todod Append Product Id
      formData.append('550c0516-3dac-40e7-95e6-ba2e9d3a40a7/' + files[0].name, files[0]);
    }
    this.uploadService
      .uploadFile(formData)      
      .subscribe(
        (data) => {
          this.dialogdata.OnboardingModel.supplierInfoCSV = data.absolutePath;   
          alert('upload completed successfully');       
        }
      ); 
  }
}
