import { Injectable, EventEmitter } from '@angular/core';
import { IChatParticipant } from 'ng-chat';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeOpenChatFunction = new EventEmitter();
  invokeShowSpinnerfunction = new EventEmitter();
  invokeHideSpinnerfunction = new EventEmitter();


  subsVar: Subscription;

  constructor() { }

  OpenChatClick(User: IChatParticipant) {
    this.invokeOpenChatFunction.emit(User);
  }
  ShowSpinnerClick() {
    this.invokeShowSpinnerfunction.emit();

  }
  HideSpinnerClick() {
    this.invokeHideSpinnerfunction.emit();

  }
}

