
import { environment } from 'environments/environment';


// this checks if the app is running on IE
export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_omniorder_susi',
    forgotPassword: 'B2C_1_Password_Reset',
    editProfile: 'B2C_1_editProfile'
  },
  authorities: {
    signUpSignIn: {
      authority: 'https://omniorderb2c.b2clogin.com/omniorderb2c.onmicrosoft.com/B2C_1_omniorder_susi'

    },
    forgotPassword: {
      authority: 'https://omniorderb2c.b2clogin.com/omniorderb2c.onmicrosoft.com/B2C_1_Password_Reset'
    },
    editProfile: {
      authority: 'https://omniorderb2c.b2clogin.com/omniorderb2c.onmicrosoft.com/B2C_1_editProfile'
    }
  },
  authorityDomain: "omniorderb2c.b2clogin.com"

};


export const apiConfig: { b2cScopes: string[]; webApi: string } = {
  b2cScopes: [`${environment.scope}`],
  // webApi: 'https://omniorderb2c.onmicrosoft.com/omniOrdersAPIs'
  webApi: `${environment.apibaseUrl}/`
};

// export const msalConfig: Configuration = {
//   auth: {
//     clientId: 'aacf9c59-3d28-4eaa-a98d-f35286728b2d',
//     authority: b2cPolicies.authorities.signUpSignIn.authority,
//     redirectUri: '/',
//     postLogoutRedirectUri: '/',
//     navigateToLoginRequestUrl: true,
//     validateAuthority: false,
//   },
//   cache: {
//     cacheLocation: 'localStorage',
//     storeAuthStateInCookie: isIE, // Set this to 'true' to save cache in cookies to address trusted zones limitations in IE
//   },
//   system: {
//     loadFrameTimeout: 800,
//     tokenRenewalOffsetSeconds: 900,
//     navigateFrameWait: 9
//   }
// }



export const loginRequest: { scopes: string[] } = {
  scopes: ['openid', 'profile'],
};

// Scopes you enter will be used for the access token request for your web API
export const tokenRequest: { scopes: string[] } = {
  scopes: apiConfig.b2cScopes // i.e. [https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read]
};
// #endregion

// #region 4) MSAL-Angular Configuration
// here you can define the coordinates and required permissions for your protected resources
export const protectedResourceMap: [string, string[]][] = [
  [apiConfig.webApi, apiConfig.b2cScopes]
  // i.e. [https://fabrikamb2chello.azurewebsites.net/hello, ['https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read']]
];