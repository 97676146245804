import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { environment } from 'environments/environment';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DashBoardViewModel } from '../models/dashboard/dashboardViewModel';
import { UtilsService } from './utils.service';
// import { PromotionGroupCollection } from '../mocks/promotionGroups-mock';
// import {  } from '../models/promotions/marketing.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class DashBoardService {

  headers: Headers;
  apiURl: string;

  private handleError: HandleError;



  constructor(private http: HttpClient, private utilsService: UtilsService, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('MarketingService');
    this.apiURl = environment.apibaseUrl;
  }


  GetSupplierDashBoard(): Observable<DashBoardViewModel> {
    const url = `${this.apiURl}/api/v1/DashBoard/GetSupplierDashBoard`;

    return this.http.get<DashBoardViewModel>(url, httpOptions).pipe(
      catchError(this.handleError<DashBoardViewModel>('GetSupplierDashBoard'))
    );
  }

}


