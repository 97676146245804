import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { environment } from 'environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { UploadResult } from 'app/shared/models/uploadresult';
import { Observable, of } from 'rxjs';
import { SupplierViewModel } from '../models/seller/supplierViewModel';

const httpOptions = {
    headers: new HttpHeaders({
        
        'x-version': '3',
        Authorization: 'my-auth-token'
    })
};
@Injectable({ providedIn: 'root' })
export class UploadService {
    headers: Headers;
    apiURl: string;

    private handleError: HandleError;

    constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {

        this.handleError = httpErrorHandler.createHandleError('UploadService');
        this.apiURl = environment.apibaseUrl;
    }

    uploadFile(formData: FormData): Observable<UploadResult> {        
                const url = `${this.apiURl}/api/v1/admin/Upload/UploadFile`;
                return this.http.post<UploadResult>(
                    url, formData, httpOptions).pipe(
                        catchError(this.handleError<UploadResult>('uploadFile')));
    }

    uploadImage(formData: FormData): Observable<UploadResult[]> {
                const url = `${this.apiURl}/api/v1/admin/Upload/UploadImage`;
                return this.http.post<UploadResult[]>(url,formData, httpOptions).pipe(
                    catchError(this.handleError<UploadResult[]>('uploadFile')));
    }
}