import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Home', icon: 'dashboard', class: '' },
  { path: '/onboarding', title: 'Onboarding', icon: 'eco', class: '' },
  { path: '/market-updates', title: 'Market Updates', icon: 'groups', class: '' }  ,
  { path: '/sellers', title: 'Sellers', icon: 'eco', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  @Input() loggedIn = false;
  @Input() username: string = '';
  @Output() editProfileClicked = new EventEmitter<void>();
  @Output() logoutClicked = new EventEmitter<void>();
  constructor() { }

  ngOnInit() {
    //debugger;
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
  EditProfile() {
    this.editProfileClicked.emit();
  }

  logout() {
    this.loggedIn = false;
    this.username = '';
    this.logoutClicked.emit();

  }

}
