<div class="main-content">
  <div class="container-fluid">

    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-header card-header-tabs card-header-success d-flex">            

           <button mat-button color="white" (click)="openmarketupdatesdialog(0)" mat-raised-button
              matTooltip="Share market update" [matTooltipPosition]="'above'"
              class="nav-tabs-title nav-link btn-primary btn-sm ml-auto p-2">
              <i class="material-icons">hearing</i>Public Market Update</button>
          </div>
          <div class="card-body">
            <div class="tab-content">
              <div class="tab-pane active" id="profile">                
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
