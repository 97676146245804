export class OrderUpdateRequest {
    supplierId?: number;
    buyerId?: number;
    orderId?: number;
    productId?: number;


    lineItemId?: number;
    qunatity?: number;


}
export class OrdersLineItemsDetailsRequest {

    supplierId: number;
    buyerId: number;
}

export class PlainProductInOrders {
    id: number;
    buyerInOrder: BuyerInOrder;
    productInOrder: ProductInOrder;
    lineItemInOrder: LineItemInOrder;
}


export class PendingOrdersbyBuyer {
    buyerInOrder: BuyerInOrder;
    totalRequested: number;
    totalSellingPrice: number;
    productsLineItemDetails: ProductLineItemDetails[];
    constructor() {

        this.buyerInOrder = new BuyerInOrder();
    }

}

export class PendingOrdersbyProduct {
    productInOrder: ProductInOrder;
    totalRequested: number;
    totalSellingPrice: number;
    buyersLineItemDetails: BuyerLineItemDetails[];
    constructor() {

        this.productInOrder = new ProductInOrder();
    }

}


export class BuyerLineItemDetails {

    constructor() {

        this.buyerInOrder = new BuyerInOrder();
        this.lineItemInOrder = new LineItemInOrder();


    }

    buyerInOrder: BuyerInOrder;
    lineItemInOrder: LineItemInOrder;

}
export class ProductLineItemDetails {

    constructor() {

        this.productInOrder = new ProductInOrder();
        this.lineItemInOrder = new LineItemInOrder();

    }

    productInOrder: ProductInOrder;
    lineItemInOrder: LineItemInOrder;

}

export class ProductInOrder {
    productId: number;
    supplierId: number;
    productCode: string;
    format: string;
    region: string;
    discription: string;
    stock: number;
    grower: string;
    grade: string;
    varietal: string;
    ripness: string;
    category: string;

}

export class LineItemInOrder {

    constructor() {
        this.updatedQuantity = 1;
    }
    lineitemId: number;
    orderId: number;
    orderDate: Date;
    requestedQuantiy: number;
    approvedQuantity: number;
    updatedQuantity: number;
    soldPrice: number;
    orderItemTotalPrice: number;

}
export class BuyerInOrder {
    buyerId: number;
    buyerName: string;
    email: string
}


