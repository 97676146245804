export const environment = {
  production: true,
  apibaseUrl: 'https://admin.omniorder.com.au',
  signUpSignInAuthority:"https://omniorderprod.b2clogin.com/omniorderprod.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN_PRD",
  forgotPasswordAuthority:"https://omniorderprod.b2clogin.com/omniorderprod.onmicrosoft.com/B2C_1A_PASSWORDRESET_PRD",
  editProfileAuthority:"https://omniorderprod.b2clogin.com/omniorderprod.onmicrosoft.com/B2C_1A_PROFILEEDIT_PRD",
  authorityDomain:"omniorderprod.b2clogin.com",
  clientId:"cb5dd606-5ae9-4d46-9a87-23c9c63f9c54",
  scope:"https://omniorderprod.onmicrosoft.com/OmniOrderAPI/access_as_user",
  postLogoutRedirectUri:"https://www.omniorder.com.au",
};
