import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { MessageService } from 'app/shared/services/message.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DialogService } from '@progress/kendo-angular-dialog';

import Swal from 'sweetalert2'


/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError =
  <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

/** Handles HttpClient errors */
@Injectable()
export class HttpErrorHandler {
  _dialogService: DialogService;
  constructor(private _injector: Injector, private messageService: MessageService, private spinner: NgxSpinnerService, private dialogService: DialogService
  ) {

    setTimeout(() => {
      this._dialogService = <DialogService>this._injector.get(DialogService);
    });

  }

  /** Create curried handleError function that already knows the service name */
  createHandleError = (serviceName = '') => {
    return <T>(operation = 'operation', result = {} as T) =>
      this.handleError(serviceName, operation, result);
  }


  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param serviceName = name of the data service that attempted the operation
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>(serviceName = '', operation = 'operation', result = {} as T) {

    return (error: HttpErrorResponse): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      const message = (error.error instanceof ErrorEvent) ?
        error.error.message :
        `server returned code ${error.status} with body "${error.error}"`;

      // TODO: better job of transforming error for user consumption
      //this.messageService.add(`${serviceName}: ${operation} failed: ${message}`);

      this.spinner.hide();

      if (error.status == 403) {
        // this.messageService.error("Please contact OmniOrder for Onboarding", true);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "You don't have permission to access this feature!",
          footer: '<a href="">Request Permission..</a>',
          showCloseButton: false,
          showCancelButton: false
        })
        // Let the app keep running by returning a safe result.
        return of(result);
      };

    }

  }
}
