import { Component, Inject, OnInit } from '@angular/core';
import { OrderUpdateRequest, ProductLineItemDetails } from 'app/shared/models/order/PlainProductInOrders.Model';


import { CancelEvent, CellClickEvent, CellCloseEvent, CreateFormGroupArgs, EditEvent, GridComponent, GridDataResult, RemoveEvent, RowArgs, SaveEvent, SelectableSettings } from '@progress/kendo-angular-grid';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { State } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import { SupplierService } from 'app/shared/services/supplier.service';
import Swal from 'sweetalert2';
import { SupplierViewModel } from 'app/shared/models/seller';
import { DatePipe } from '@angular/common';
import { StripeSubscriptionStatus } from 'app/shared/models/seller/supplierViewModel';
import { MatDialog } from '@angular/material/dialog';
import { SubscriberApprovalDialog } from '../dialogs/approval/subscriber-approval.dialog';
@Component({
  selector: 'app-sellers',
  templateUrl: './sellers.component.html',
  styleUrls: ['./sellers.component.css']
})
export class SellersComponent implements OnInit {
  public view: Observable<GridDataResult>;
  public gridView: SupplierViewModel[];
  public gridData: any[];
  public mySelection: string[] = [];
  public selectedUID: string = '';
  public loading: boolean = true;
  public isEdited = false;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 5,
  };
  public selectableSettings: SelectableSettings;
  ProductLineItemsDetails: ProductLineItemDetails[];
  public formGroup: FormGroup;
  orderUpdateRequest: OrderUpdateRequest = new OrderUpdateRequest();
  snackbar: any;
  horizontalPosition: any;
  verticalPosition: any;
  editableForm: boolean;
  constructor(private spinner: NgxSpinnerService, private suppliersService: SupplierService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.suppliersService.getAllSuppliers().subscribe(
      (data) => {
        this.spinner.hide();
        this.gridView = data;
        console.log(data);
        this.loading = false;
      }
    );
  }


  public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }
  public mySelectionKey(context: RowArgs): string {
    //
    return context.dataItem.id;
  }
  keyChange(e) {
    this.selectedUID = e;
  }

  isEditDisabled() {
    console.log(this.selectedUID[0]);
    return (this.selectedUID == null || this.selectedUID.length == 0);
  }
  approve(supplier: SupplierViewModel) {
    var Data = { seller: supplier }
    const approvalDialog = this.dialog.open(SubscriberApprovalDialog, { data: Data });
    approvalDialog.afterClosed().subscribe(result => {
      this.load();
    });

  }
  saveChanges() {
    let data = this.gridView.filter(s => s.sellerSubscription != null);
    this.spinner.show();
    this.suppliersService.updateSellers(data).subscribe((result) => {
      this.spinner.hide();
      this.load();
    });
  }
  isTrialDisabled(statusId: any) {

    if (statusId == null) {
      return true;
    }
    if (statusId == StripeSubscriptionStatus.Trialing) {
      return false;
    }
    else {
      return true;
    }

  }
}
