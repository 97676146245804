import { Component, OnInit, ElementRef, Input, EventEmitter, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'app/shared/services/utils.service';
import { AdminService } from 'app/shared/services/Admin.Service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { NotificationViewModel } from 'app/shared/models/seller/notificationViewmodel';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';

export class notification {
  id: number;
  notificationMessage: string;
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @Input() loggedIn = false;
  @Input() username: string = '';
  @Input() updateUsername(event) {
    // //console.log("event fired: ")
    // //console.log(event)
  }
  @Output() editProfileClicked = new EventEmitter<void>();
  @Output() logoutClicked = new EventEmitter<void>();
  private listTitles: any[];
  notidications: NotificationViewModel[];
  notidicationsCount: Number = 0;

  location: Location;
  mobile_menu_visible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;
  isIframe = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    location: Location,
    private msalBroadcastService: MsalBroadcastService,
    private adminService: AdminService,
    private element: ElementRef,
    private router: Router,
  ) {

    //debugger;
    this.location = location;
    this.sidebarVisible = false;

  }
  // ngOnChanges(){
  //   //console.log("username: "+this.username );
  // }
  ngOnInit() {
    this.initNavBar();



  }
  async initNavBar() {

    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
      var $layer: any = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
        this.mobile_menu_visible = 0;
      }
    });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        this.loadNotiifcations();
      })

  }
  loadNotiifcations() {    

  }
  closenotification(notification: NotificationViewModel) {    
  }
  EditProfile() {
    this.editProfileClicked.emit();
  }

  logout() {
    this.loggedIn = false;
    this.username = '';
    this.logoutClicked.emit();

  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);

    body.classList.add('nav-open');

    this.sidebarVisible = true;
  };
  sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    if (typeof this.toggleButton != "undefined") {
      this.toggleButton.classList.remove('toggled');
    }


    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  };
  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    var $toggle = document.getElementsByClassName('navbar-toggler')[0];

    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
    const body = document.getElementsByTagName('body')[0];

    if (this.mobile_menu_visible == 1) {
      // $('html').removeClass('nav-open');
      body.classList.remove('nav-open');
      if ($layer) {
        $layer.remove();
      }
      setTimeout(function () {
        $toggle.classList.remove('toggled');
      }, 400);

      this.mobile_menu_visible = 0;
    } else {
      setTimeout(function () {
        $toggle.classList.add('toggled');
      }, 430);

      var $layer = document.createElement('div');
      $layer.setAttribute('class', 'close-layer');


      if (body.querySelectorAll('.main-panel')) {
        document.getElementsByClassName('main-panel')[0].appendChild($layer);
      } else if (body.classList.contains('off-canvas-sidebar')) {
        document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
      }

      setTimeout(function () {
        $layer.classList.add('visible');
      }, 100);

      $layer.onclick = function () { //asign a function
        body.classList.remove('nav-open');
        this.mobile_menu_visible = 0;
        $layer.classList.remove('visible');
        setTimeout(function () {
          $layer.remove();
          $toggle.classList.remove('toggled');
        }, 400);
      }.bind(this);

      body.classList.add('nav-open');
      this.mobile_menu_visible = 1;

    }
  };
  getTitle() {
    //It loads a lot
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }
}

