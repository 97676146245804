import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { environment } from '../../../environments/environment';

import { ProductViewMdel } from 'app/shared/models/catalogue/index';
import { SupplierViewModel } from '../models/seller';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from 'app/base/http-error-handler.service';
import { SupplierOnboardingViewModel } from '../models/seller/supplierOnboardingViewModel';
import { Console } from 'console';
import { PricingOptionModel } from '../models/seller/pricingOptionModel';
import { SubscriptionModel } from '../models/seller/subscriptionModel';
import { subscriptionApprovalResult } from '../models/seller/subscriptionApprovalResult';



const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'x-version': '3',
    Authorization: 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  //  options: RequestOptions;
  headers: Headers;
  apiURl: string;
  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('SubscriptionService');
    this.apiURl = environment.apibaseUrl;
  }



  getAllPricingTiers(): Observable<PricingOptionModel[]> {
    const url = `${this.apiURl}/api/v1/admin/subscription/prices`;
    return this.http.get<PricingOptionModel[]>(url, httpOptions).pipe(
      catchError(this.handleError<any>('updateSupplier'))
    );
  }

  approveSubscription(model: SubscriptionModel): Observable<subscriptionApprovalResult> {
    const url = `${this.apiURl}/api/v1/admin/subscription`;
    return this.http.post<subscriptionApprovalResult>(url, model, httpOptions).pipe(
      catchError(this.handleError<any>('approveSubscription'))
    );
  }
}

