import { formatDate } from '@angular/common';
import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[dateValidator][formControlName],[dateValidator][formControl],[dateValidator][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: DateValidatorDirective, multi: true,}]
})
export class DateValidatorDirective implements Validator {

  
  validate(c: FormControl): {[key: string]: any} {
    let today : any = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    console.log(today);
    
    console.log(c.value);
      let v = c.value;
      return ( today > v )? {"dateValidator": true} : null;
  }
} 