import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { environment } from '../../../environments/environment';

import { ProductViewMdel } from 'app/shared/models/catalogue/index';
import { SupplierViewModel } from '../models/seller';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from 'app/base/http-error-handler.service';
import { SupplierOnboardingViewModel } from '../models/seller/supplierOnboardingViewModel';
import { Console } from 'console';



const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'x-version': '3',
    Authorization: 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  //  options: RequestOptions;
  headers: Headers;
  apiURl: string;
  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('SupplierService');
    this.apiURl = environment.apibaseUrl;
  }



  getAllSuppliers(): Observable<SupplierViewModel[]> {

    const url = `${this.apiURl}/api/v1/admin/Onboarding/GetSuppliers`;
    return this.http.get<SupplierViewModel[]>(url, httpOptions).pipe(
      tap(results => {
        results.sort(function (a, b) { return a.id - b.id })
      }),
      catchError(this.handleError<SupplierViewModel[]>('getProductsforSupplier'))
    );
  }

  
  onboardNewSupplier(OnboardingModel: SupplierOnboardingViewModel): Observable<any> {

     const url = `${this.apiURl}/api/v1//admin/v1/Onboarding/OnboardSupplier`;
     return this.http.post<any>(url,OnboardingModel, httpOptions).pipe(
       catchError(this.handleError<any>('updateSupplier'))
     );
  }

  approve(supplier: SupplierViewModel): Observable<any> {

    const url = `${this.apiURl}/api/v1/admin/Onboarding/Approve`;
    return this.http.post<any>(url,supplier, httpOptions).pipe(
      catchError(this.handleError<any>('approveSupplier'))
    );
 }
 updateSellers(suppliers: SupplierViewModel[]): Observable<any> {

  const url = `${this.apiURl}/api/v1/admin/Onboarding/UpdateSellers`;
  return this.http.post<any>(url,suppliers, httpOptions).pipe(
    catchError(this.handleError<any>('UpdateSellers'))
  );
}
}

