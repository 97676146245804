import { Component, Inject, OnInit } from '@angular/core';
import { ProductCategory, ProductGrade, ProductGrower, ProductVarital, ProductViewMdel } from 'app/shared/models/catalogue/index';
import { ProductService } from 'app/shared/services/product.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OnboardingDialog } from 'app/dialogs/onboarding/onboarding.dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SupplierViewModel } from 'app/shared/models/seller';
import { ConfirmationDialog } from 'app/dialogs/confirm/confirm.dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { SupplierService } from 'app/shared/services/supplier.service';
import { SupplierOnboardingViewModel } from 'app/shared/models/seller/supplierOnboardingViewModel';


@Component({
  selector: 'app-inventory',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css']
})
export class OnboardingComponent implements OnInit {
  decimalPattern = new RegExp('[0-9]+([.][0-9]+)?');
  Products: ProductViewMdel[];
  Suppliers: SupplierViewModel[];
  newstock: number = 1;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  Product: ProductViewMdel;
  OnboardingModel: SupplierOnboardingViewModel;
  SupplierId: number;

  ConfirmationdialogRef: MatDialogRef<ConfirmationDialog>;

  constructor(
    private ProductService: ProductService, public dialog: MatDialog, private snackbar: MatSnackBar, private spinner: NgxSpinnerService, private suppliersService: SupplierService
    ) {

  }


  ngOnInit(): void {
    this.initSuppliers();
  }
  async initSuppliers() {
    this.spinner.show()

    this.getSuppliers()

  }

  getSuppliers(): void {

    this.suppliersService.getAllSuppliers().subscribe(
      (data) => {
        this.spinner.hide();
        this.Suppliers = data;
      }
    );
  }
  
  opendialog(editmode: boolean, onboardingModel: SupplierOnboardingViewModel = null): void {
    // //console.log(product)
    if (!editmode) {
      onboardingModel = new SupplierOnboardingViewModel();
      //todo get current supplier Id
    }
    var Data = { editmode: editmode, OnboardingModel: onboardingModel }
    const dialogRef2 = this.dialog.open(OnboardingDialog, { data: Data });
    dialogRef2.afterClosed().subscribe(result => {
      this.getSuppliers();
    });

  }
  
  public opened = false;

  public close(status) {
    //console.log(`Dialog result: ${status}`);
    this.opened = false;
  }

  public open() {
    this.opened = true;
  }

  public value = 5;
  public events: string[] = [];
  public onEnter(Product: ProductViewMdel) {
    //this.openConfirmationDialog(Product)
    //this.opened = true;
    // alert(object.id + '' + object.unitPrice);
  }
  public onFocus(): void {
    this.log("NumericTextBox is focused");
  }

  public onBlur(): void {
    this.log("NumericTextBox is blurred");
  }

  public onChange(value: string): void {
    this.log(`valueChange ${value}`);
  }

  private log(event: string): void {
    this.events.unshift(`${event}`);
  }  

}