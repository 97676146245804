<mat-card>
  <mat-card-header class="mb-3">  
    <mat-card-title class="cardHeader"> Supplier Onboarding Settings</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <section class="d-flex">
      
        <mat-label>Customers CSV</mat-label>
        <input  #fileInput type="file" id="file" (change)="uploadCustomers(fileInput.files)" />
      
    </section>
    <section class="d-flex">
      
        <mat-label>Products CSV</mat-label>
        <input  #fileInput2 type="file" id="file2" (change)="uploadProducts(fileInput2.files)" />
      
    </section>
    <section class="d-flex">
      
        <mat-label>Supplier Info CSV</mat-label>
        <input  #fileInput3 type="file" id="file3" (change)="uploadSupplierInfo(fileInput3.files)" />
      
    </section>   
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="saveOrUpdateSupplierOnboarding()"  cdkFocusInitial>Onboard</button>
  </mat-card-actions>
</mat-card>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> applying changes... </p>
</ngx-spinner>
