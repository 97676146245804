import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { DataBindingDirective } from "@progress/kendo-angular-grid";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SellerViewModel } from 'app/shared/models/seller/sellerViewModel';
import { ProductGrower, ProductVarital } from 'app/shared/models/catalogue/lockups';
import { SupplierViewModel } from 'app/shared/models/seller';
import { SubscriptionService } from 'app/shared/services/subscription.service';
import { PricingOptionModel } from 'app/shared/models/seller/pricingOptionModel';
import { SubscriptionModel } from 'app/shared/models/seller/subscriptionModel';


@Component({
  selector: 'app-subscriber-approval',
  templateUrl: './subscriber-approval.html',
  styleUrls: ['./subscriber-approval.css']
})
export class SubscriberApprovalDialog implements OnInit {
  public opened = true;
  public subscriptionForm: FormGroup;
  public isLoading: boolean = false;
  public isEditableForm: boolean = true;
  public prices: PricingOptionModel[];
  public priceId: string;
  public trialDays: number;
  public discountPeriod: number;
  public discount: number;
  subscription: SubscriptionModel;

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  constructor(public dialogRef: MatDialogRef<SubscriberApprovalDialog>, private spinner: NgxSpinnerService,
    public subscriptionservice: SubscriptionService,
    @Inject(MAT_DIALOG_DATA) public data: { seller: SupplierViewModel }
    , public dialog: MatDialog,
    public fb: FormBuilder, private snackbar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.subscriptionForm = this.fb.group(
      {
        priceId: [{ value: "", disabled: false }, [Validators.required,]],
        trialDays: [{ value: "0", disabled: false }, [Validators.required,]],
        discount: [{ value: "0", disabled: false }, [Validators.required,]],
        discountPeriod: [{ value: "0", disabled: false }, [Validators.required,]],
      }
    );
    this.load();
  }

  load(): void {
    this.subscriptionservice.getAllPricingTiers().subscribe(
      (data) => {
        this.spinner.hide();
        this.prices = data;
        this.subscriptionForm = this.fb.group(
          {
            priceId: [{ value: "", disabled: false }, [Validators.required,]],
            trialDays: [{ value: "0", disabled: false }, [Validators.required,]],
            discount: [{ value: "0", disabled: false }, [Validators.required,]],
            discountPeriod: [{ value: "0", disabled: false }, [Validators.required,]],
          }
        );
        this.subscriptionForm.patchValue({
          trialDays: 0,
        });
      }
    );
  }
  get f() { return this.subscriptionForm.controls; }

  public close(status: string): void {
    this.opened = false;
  }

  onSubmit() {
    this.spinner.show();
    let model: SubscriptionModel = this.subscriptionForm.value;
    console.log(model);

    if (this.data?.seller?.id) {
      model.sellerId = this.data.seller.id;
      this.subscriptionservice.approveSubscription(model).subscribe((data) => {
        this.spinner.hide();
        this.dialogRef.close();
      });
    }
  }
}
