import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { environment } from 'environments/environment';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MarketingGroupBaseViewModel, SupplierGroupsViewModel, SupplierTrustedNetworks, SupplierTrustedNetworksViewModel } from '../models/promotions/marketing.model';
import { MarketUpdateViewModel } from '../models/promotions/marketupdate.model';

import { UtilsService } from './utils.service';



const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token',
        'x-version': '3'
    })
};

@Injectable({
    providedIn: 'root'
})
export class MarketingService {

    headers: Headers;
    apiURl: string;

    private handleError: HandleError;



    constructor(private http: HttpClient, private utilsService: UtilsService, httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('MarketingService');
        this.apiURl = environment.apibaseUrl;
    }

    PostMarketingUpdate(marketUpdateViewModel: MarketUpdateViewModel): Observable<any> {
        const url = `${this.apiURl}/api/v1/admin/MarketUpdates/PostMarketingUpdate`;

        return this.http.post<any>(url, marketUpdateViewModel, httpOptions).pipe(
            catchError(this.handleError<any>('PostMarketingUpdate'))
        );
    }
    GetSupplierGroups(): Observable<SupplierGroupsViewModel> {
        const url = `${this.apiURl}/api/v1/Marketing/GetSupplierGroups`;

        return this.http.get<SupplierGroupsViewModel>(url, httpOptions).pipe(
            catchError(this.handleError<SupplierGroupsViewModel>('GetSupplierGroups'))
        );
    }

    UpdateMarketingGroup(marketingGroupViewModel: MarketingGroupBaseViewModel): Observable<SupplierGroupsViewModel> {
        const url = `${this.apiURl}/api/v1/Marketing/UpdateMarketingGroup`;

        return this.http.put<SupplierGroupsViewModel>(url, marketingGroupViewModel, httpOptions).pipe(
            catchError(this.handleError<SupplierGroupsViewModel>('UpdateMarketingGroup'))
        );
    }
    CreateMarketingGroup(marketingGroupViewModel: MarketingGroupBaseViewModel): Observable<SupplierGroupsViewModel> {
        const url = `${this.apiURl}/api/v1/Marketing/CreateMarketingGroup`;

        return this.http.post<SupplierGroupsViewModel>(url, marketingGroupViewModel, httpOptions).pipe(
            catchError(this.handleError<SupplierGroupsViewModel>('CreateMarketingGroup'))
        );
    }

    ActivateOrDeactivateMarketingGroup(marketingGroupViewModel: MarketingGroupBaseViewModel): Observable<SupplierGroupsViewModel> {
        const url = `${this.apiURl}/api/v1/Marketing/ActivateOrDeactivateMarketingGroup`;

        return this.http.post<SupplierGroupsViewModel>(url, marketingGroupViewModel, httpOptions).pipe(
            catchError(this.handleError<SupplierGroupsViewModel>('ActivateOrDeactivateMarketingGroup'))
        );
    }

    updateProductCatgegoryGroup(GroupId: number, ProductCategories: any[]): Observable<any> {
        const url = `${this.apiURl}/api/v1/Marketing/updateProductCatgegoryGroup?GroupId=${GroupId}`;

        return this.http.post<any>(url, ProductCategories, httpOptions).pipe(
            catchError(this.handleError<any>('updateProductCatgegoryGroup'))
        );
    }
    updateMarketingGroupMembers(GroupId: number, Members: any[]): Observable<any> {
        const url = `${this.apiURl}/api/v1/Marketing/updateMarketingGroupMembers?GroupId=${GroupId}`;

        return this.http.post<any>(url, Members, httpOptions).pipe(
            catchError(this.handleError<any>('updateMarketingGroupMembers'))
        );
    }


    AddProductCatgegoryToGroup(CategoryId: number, marketingGroupViewModel: MarketingGroupBaseViewModel): Observable<SupplierGroupsViewModel> {
        const url = `${this.apiURl}/api/v1/Marketing/AddProductCatgegoryToGroup?CategoryId=${CategoryId}`;

        return this.http.put<SupplierGroupsViewModel>(url, marketingGroupViewModel, httpOptions).pipe(
            catchError(this.handleError<SupplierGroupsViewModel>('AddProductCatgegoryToGroup'))
        );
    }
    RemoveProductCatgegoryFromGroup(CategoryId: number, marketingGroupViewModel: MarketingGroupBaseViewModel): Observable<SupplierGroupsViewModel> {
        const url = `${this.apiURl}/api/v1/Marketing/RemoveProductCatgegoryFromGroup?CategoryId=${CategoryId}`;

        return this.http.put<SupplierGroupsViewModel>(url, marketingGroupViewModel, httpOptions).pipe(
            catchError(this.handleError<SupplierGroupsViewModel>('RemoveProductCatgegoryFromGroup'))
        );
    }
    AddGroupMember(MemberId: number, marketingGroupViewModel: MarketingGroupBaseViewModel): Observable<SupplierGroupsViewModel> {
        const url = `${this.apiURl}/api/v1/Marketing/AddGroupMember?MemberId=${MemberId}`;

        return this.http.put<SupplierGroupsViewModel>(url, marketingGroupViewModel, httpOptions).pipe(
            catchError(this.handleError<SupplierGroupsViewModel>('AddGroupMember'))
        );
    }
    RemoveGroupMember(MemberId: number, marketingGroupViewModel: MarketingGroupBaseViewModel): Observable<SupplierGroupsViewModel> {
        const url = `${this.apiURl}/api/v1/Marketing/RemoveGroupMember?MemberId=${MemberId}`;

        return this.http.put<SupplierGroupsViewModel>(url, marketingGroupViewModel, httpOptions).pipe(
            catchError(this.handleError<SupplierGroupsViewModel>('RemoveGroupMember'))
        );
    }

    GetBuyersInSupllierNetwork(): Observable<SupplierTrustedNetworksViewModel> {
        const url = `${this.apiURl}/api/v1/Supplier/GetBuyersInSupllierNetwork`;

        return this.http.get<SupplierTrustedNetworksViewModel>(url, httpOptions).pipe(
            catchError(this.handleError<SupplierTrustedNetworksViewModel>('GetBuyersInSupllierNetwork'))
        );
    }

    ActivateOrDeactivateMemberJoiningSupplierNetwork(supplierTrustedNetworks: SupplierTrustedNetworks): Observable<SupplierTrustedNetworksViewModel> {
        const url = `${this.apiURl}/api/v1/Supplier/ActivateOrDeactivateMemberJoiningSupplierNetwork`;

        return this.http.post<SupplierTrustedNetworksViewModel>(url, supplierTrustedNetworks, httpOptions).pipe(
            catchError(this.handleError<SupplierTrustedNetworksViewModel>('ActivateOrDeactivateMemberJoiningSupplierNetwork'))
        );
    }

    RejectJoiningSupplierNetwork(supplierTrustedNetworks: SupplierTrustedNetworks): Observable<SupplierTrustedNetworksViewModel> {
        const url = `${this.apiURl}/api/v1/Supplier/RejectJoiningSupplierNetwork`;

        return this.http.post<SupplierTrustedNetworksViewModel>(url, supplierTrustedNetworks, httpOptions).pipe(
            catchError(this.handleError<SupplierTrustedNetworksViewModel>('RejectJoiningSupplierNetwork'))
        );
    }

    AproveJoiningSupplierNetwork(supplierTrustedNetworks: SupplierTrustedNetworks): Observable<SupplierTrustedNetworksViewModel> {
        const url = `${this.apiURl}/api/v1/Supplier/AproveJoiningSupplierNetwork`;

        return this.http.post<SupplierTrustedNetworksViewModel>(url, supplierTrustedNetworks, httpOptions).pipe(
            catchError(this.handleError<SupplierTrustedNetworksViewModel>('AproveJoiningSupplierNetwork'))
        );
    }
}
