<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute fixed-top" *ngIf="loggedIn">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
    </div>
    <button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <!-- <form class="navbar-form">
        <div class="input-group no-border">
          <input type="text" value="" style="min-width:300px" class="form-control" placeholder="Search...">
          <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </form> -->
      <ul class="navbar-nav">
        <!-- <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)">
                        <i class="material-icons">Home</i>
                        <p>
                            <span class="d-lg-none d-md-block">Stats</span>
                        </p>
                    </a>
                </li> -->
        <li class="nav-item dropdown" *ngIf="loggedIn">
          <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">notifications</i>
            <span class="notification">{{notidicationsCount}}</span>
          </a>
          <div *ngIf="notidicationsCount>0" class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuLink">
            <a *ngFor="let notification of notidications" class="dropdown-item" href="javascript:void(0)">
              {{notification.notificationMessage}}
              <button mat-raised-button type="button" matTooltip="close" [matTooltipPosition]="'above'"
                class="btn btn-danger btn-link btn-sm btn-just-icon" (click)="closenotification(notification)">
                <i class="material-icons">close</i>
              </button>
            </a>

          </div>
        </li>

        <li class="nav-item dropdown" *ngIf="loggedIn">
          <a mat-raised-button matTooltip="click to logout or edit" [matTooltipPosition]="'above'"
            class="nav-link btn btn-success" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <i class="material-icons">person</i>
            <div class="ripple-container"></div>
            {{username}}
          </a>
          <!-- <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons" style="color:rgb(6, 116, 30)">person</i>
                        <p>
                            <span class="d-lg-none d-md-block">Account</span>
                        </p>
                    </a> -->
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">

            <a class="dropdown-item nav-link" mat-button color="info" href="javascript:void(0)" (click)="editProfile()">

              <i class="material-icons" style="color:rgb(15, 78, 29)"> mode_edit</i>
              Edit Profile
            </a>
            <a class="dropdown-item nav-link" mat-button color="error" href="javascript:void(0)" (click)="logout()">

              <i class="material-icons green" style="color:rgb(255, 0, 0)"> logout</i>

              Logout

            </a>

          </div>
        </li>
        <li class="nav-item" *ngIf="!loggedIn">
          <button mat-raised-button matTooltip="click to login" [matTooltipPosition]="'above'" type="submit"
            (click)="login()" class="btn btn-primary">

            <i class="material-icons">login</i>
            <div class="ripple-container"></div>
            Login
          </button>
          <!-- <button mat-button color="primary"   (click)="login()" mat-raised-button 
                        matTooltip="click to login"
                        [matTooltipPosition]="'above'"
                        class="btn btn-primary btn-link btn-lg"
                       >
                           <i class="material-icons">login</i> Login</button> -->
          <!-- <a class="nav-link" href="javascript:void(0)" (click)="login()" id="navbarDropdownMenuLink">
                        <i class="material-icons">login</i>
                    
                          Login
                        
                    </a> -->
        </li>
      </ul>
    </div>
  </div>
</nav>


<!-- 
<nav class="navbar navbar-transparent navbar-absolute">
    <div class="container-fluid">
        <div class="navbar-header">
            <button mat-raised-button type="button" class="navbar-toggle" data-toggle="collapse" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
        </div>
        <div class="collapse navbar-collapse">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="material-icons">dashboard</i>
                        <p class="hidden-lg hidden-md">Dashboard</p>
                    </a>
                </li>
                <li class="dropdown">
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="material-icons">notifications</i>
                        <span class="notification">5</span>
                        <p class="hidden-lg hidden-md">Notifications</p>
                    </a>
                    <ul class="dropdown-menu">
                        <li><a href="javascript:void(0)">Mike John responded to your email</a></li>
                        <li><a href="javascript:void(0)">You have 5 new tasks</a></li>
                        <li><a href="javascript:void(0)">You're now friend with Andrew</a></li>
                        <li><a href="javascript:void(0)">Another Notification</a></li>
                        <li><a href="javascript:void(0)">Another One</a></li>
                    </ul>
                </li>
                <li>
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                       <i class="material-icons">person</i>
                       <p class="hidden-lg hidden-md">Profile</p>
                    </a>
                </li>
            </ul>

            <form class="navbar-form navbar-right" role="search">
                <div class="form-group form-black is-empty">
                    <input type="text" class="form-control" placeholder="Search">
                    <span class="material-input"></span>
                </div>
                <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                    <i class="material-icons">search</i><div class="ripple-container"></div>
                </button>
            </form>
        </div>
    </div>
</nav>  -->
