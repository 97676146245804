<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-header card-header-tabs card-header-success">


            <button mat-raised-button matTooltip="Onboard New Supplier" [matTooltipPosition]="'above'"
              class="btn btn-info btn-sm" (click)="opendialog(false,OnboardingModel)">
              <i class="material-icons">note_add</i> Onboard New Supplier
            </button>

          </div>
          <div class="card-body">
            <div class="table-responsive">

              <table class="table">
                <thead>
                  <th>
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-lg-1 col-md-1 text-center"></div>
                        <div class="form-check col-lg-5 col-md-5">
                          <a mat-button class="nav-link">
                            Onboarded Suppliers
                            <div class="ripple-container"></div>
                          </a>
                        </div>                        
                      </div>
                    </div>
                  </th>

                </thead>
                <tbody>
                  <tr *ngFor="let Supplier of Suppliers ; let SupplierIndex=index">
                    <td>
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-lg-1 col-md-1">
                            <button mat-raised-button type="button" matTooltip="Edit Supplier settings"
                              [matTooltipPosition]="'above'" class="btn btn-primary btn-link btn-sm btn-just-icon"
                              (click)="opendialog(true,Supplier)">
                              <i class="material-icons">settings</i>
                            </button>                              
                          </div>       
                          <div class="form-check col-lg-5 col-md-5">
                            {{Supplier.businessName}} - {{Supplier.ownerName}} - {{Supplier.businessEmail}}
                          </div>                                             
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="Suppliers?.length === 0">No records found</div>
          </div>
        </div>
      </div>
    </div>
    <kendo-dialog title="Please confirm" *ngIf="opened" (close)="close('cancel')" [minWidth]="250" [width]="200">
      <p style="margin: 30px; text-align: center;">
        Are you sure you want to continue?
      </p>
      <kendo-dialog-actions>
        <button kendoButton (click)="close('no')">No</button>
        <button kendoButton (click)="close('yes')" primary="true">Yes</button>
      </kendo-dialog-actions>
    </kendo-dialog>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading Suppliers... </p>
</ngx-spinner>
